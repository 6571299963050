//  @flow

import "deprecated/data-visualizers/BootstrapTable/BootstrapTable.less";

import { Link } from "components/ui-deprecated";
import type { FormatConfig } from "deprecated/data-utils";
import { format } from "deprecated/data-utils";
import type { DataWrapper } from "deprecated/data-wrapper";
import { fields, map } from "deprecated/data-wrapper";

export type BootstrapTableProps = {
    // DataWrapper
    data: DataWrapper;

    /**
     * Adds borders on all sides of the table and cells.
     */
    bordered: boolean;

    /**
     * Removes all borders on the table and cells, including table header.
     */
    borderless: boolean;

    /**
     * Enable a hover state on table rows within a <tbody>.
     */
    hover: boolean;

    /**
     * Responsive tables allow tables to be scrolled horizontally with ease.
     * Across every breakpoint, use responsive for horizontally scrolling tables.
     * Responsive tables are wrapped automatically in a div.
     * Use responsive="sm", responsive="md", responsive="lg", or responsive="xl"
     * as needed to create responsive tables up to a particular breakpoint.
     * From that breakpoint and up, the table will behave normally and not scroll horizontally.
     */
    responsive?: "sm" | "md" | "lg" | "xl";

    /**
     * Make tables more compact by cutting cell padding in half by setting size as sm.
     */
    small: boolean;

    /**
     * Adds zebra-striping to any table row within the <tbody>.
     */
    striped: boolean;

    /**
     * Invert the colors of the table — with light text on dark backgrounds by setting variant as dark.
     */
    variant?: "dark";

    /**
     * TODO: write it
     */
    links: Record<string, string>;
} & FormatConfig;

BootstrapTable.defaultProps = {
    bordered: true,
    borderless: false,
    hover: true,
    small: true,
    striped: true,
    links: {},
};

export default function BootstrapTable(props: BootstrapTableProps) {
    const { data, small, bordered, borderless, hover, responsive, striped, variant, links } = props;
    const linkFields = Object.keys(links).map((field) => links[field]);
    const formatter = format(props);
    const headers = fields(data).filter((field) => linkFields.indexOf(field) === -1);
    return (
        <table className={"datamole-data-visualizers-bootstrap-table"}>
            <thead>
                <tr>
                    {headers.map((header) => (
                        <th key={header}>{formatter(header)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {map(data, (row, i) => (
                    <tr key={i}>
                        {headers.map((header) => (
                            <td key={header}>
                                {typeof links[header] === "string" && (
                                    <Link to={String(row[links[header]])}>{formatter(row[header], header)}</Link>
                                )}
                                {typeof links[header] !== "string" && formatter(row[header], header)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
